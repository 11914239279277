<template>
    <div class="code-wrapper">
        <img src="../../assets/img/ceshi/code.png" alt="">
        <span class="download-tip">右键点击二维码，选择“图片另存为”，保存到本地。</span>
    </div>
</template>

<script>
    export default {
        name: "CodeModule"
    }
</script>

<style scoped lang="scss">
    .code-wrapper{
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        height: 116px;
        img{
            width: 116px;
            height: 116px;
            vertical-align: middle;
        }
        .download-tip{
            margin-top: 16px;
            color: #999;
            margin-left: 12px;
            width: auto;
            text-align: left;
        }
    }
</style>