<template>
    <div class="immediatePromotion-wrapper">
        <div class="immediatePromotion-top">
            <div class="top-title">立即推广</div>
            <div class="btn-blue" @click="returnIndex()">返回</div>
        </div>
        <div class="immediatePromotion-container">
                <div class="immediatePromotion-container-left">

                    <el-scrollbar class="immediatePromotion-list-scrollbar" :native="false">
                        <div class="immediatePromotion-box">
                            <span class="form-item-title">图片素材</span>
                            <div class="immediatePromotion-item">
                                <div class="pic-wrap">
                                    <div class="pic-item" v-for="(item,index) in imgList">
                                        <div class="pic" :class="{'default':index===0,'checked':item.state}" @click="imgClick(index)">
                                            <img :src="item.src" alt="">
                                        </div>
                                    </div>
                                </div>
                                <div class="toolbar">
                                    <el-button size="medium" class="copy-btn" @click="copyImg">复制图片</el-button>
                                    <div class="clipboard-tip" v-if="isCopyImg">
                                        <div class="clipboard-tip-s">
                                            <i class="iconfont">&#xe67e;</i><span>复制成功</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="immediatePromotion-box">
                            <span class="form-item-title">文案素材</span>
                            <div class="immediatePromotion-item">
                                <el-radio-group v-model="immediatePromotion_radio" class="radio-orange" @change="changeRadio">
                                    <el-radio :label="1">短链接
                                        <el-tooltip  effect="light" placement="top">
                                            <div slot="content">
                                                1、有效期：300天，过期失效需要重新获取。
                                                <br/> 2、请勿将此推广链接打开后再发送给用户，否则无法跟踪。
                                                <br/>3、若订单使用红包或购物券后佣金有可能支付给红包推广者，如您是自推自买， 请勿使用他人分享的红包及购物券。红包推广是什么？ Ps:当面付、火车票或者理财保险类商品不支持佣金"
                                            </div>
                                            <i class="iconfont tooltip-icon" style="margin-left: 10px">&#xe69c;</i>
                                        </el-tooltip>
                                    </el-radio>
                                    <el-radio :label="2">淘口令
                                        <el-tooltip placement="top" effect="light">
                                            <div slot="content">
                                                1、有效期：30天，过期失效需要重新获取。
                                                <br/> 2、若订单使用红包或购物券后佣金有可能支付给红包推广者，如您是自推自买， 请勿使用他人分享的红包及购物券。红包推广是什么？ Ps:当面付、火车票或者理财保险类商品不支持佣金
                                            </div>
                                            <i class="iconfont tooltip-icon" style="margin-left: 10px">&#xe69c;</i>
                                        </el-tooltip>
                                    </el-radio>
                                    <el-radio :label="3">长链接
                                        <el-tooltip placement="top" effect="light">
                                            <div slot="content">
                                                1、请勿将此推广链接打开后再发送给用户，否则无法跟踪。
                                                <br/> 2、若订单使用红包或购物券后佣金有可能支付给红包推广者，如您是自推自买， 请勿使用他人分享的红包及购物券。红包推广是什么？ Ps:当面付、火车票或者理财保险类商品不支持佣金
                                            </div>
                                            <i class="iconfont tooltip-icon" style="margin-left: 10px">&#xe69c;</i>
                                        </el-tooltip>
                                    </el-radio>
                                    <el-radio :label="4">二维码
                                        <el-tooltip  placement="top" effect="light">
                                            <div slot="content">
                                                1、有效期：300天，过期失效需要重新获取。
                                                <br/> 2、若订单使用红包或购物券后佣金有可能支付给红包推广者，如您是自推自买， 请勿使用他人分享的红包及购物券。红包推广是什么？ Ps:当面付、火车票或者理财保险类商品不支持佣金
                                            </div>
                                            <i class="iconfont tooltip-icon" style="margin-left: 10px">&#xe69c;</i>
                                        </el-tooltip>
                                    </el-radio>
                                </el-radio-group>
                                <div class="copywriting-wrapper" v-if="immediatePromotion_radio===1">
                                    <ShortLinkModule ref="ShortLinkModule"/>
                                </div>
                                <div class="copywriting-wrapper" v-if="immediatePromotion_radio===2">
                                    <SearchPasswordModule ref="SearchPasswordModule"/>
                                </div>
                                <div class="copywriting-wrapper" v-if="immediatePromotion_radio===3">
                                    <LongLinkModule ref="LongLinkModule"/>
                                </div>
                                <div class="copywriting-wrapper" v-if="immediatePromotion_radio===4">
                                    <CodeModule/>
                                </div>
                            </div>
                        </div>

                    </el-scrollbar>
                </div>
                <div class="immediatePromotion-container-right"   v-if="immediatePromotion_radio!==4">

                    <el-scrollbar class="immediatePromotion-list-scrollbar">
                        <div class="img-wrap">
                            <img :src="allCopy.img" alt="">
                        </div>
                        <div class="text-review">
                            <el-input type="textarea" v-model="allCopy.title" :readonly="true" ref="myAllCopyTextarea"></el-input>
<!--                            <div ref="CopyImgAll">-->
<!--                                {{allCopy.title}}-->
<!--                            </div>-->
                        </div>
                        <div class="toolbar">
                            <el-button size="medium" class="copy-btn"  v-clipboard:copyhttplist="allCopy.title" v-clipboard:success="copyImgAll">复制图片</el-button>
                            <div class="clipboard-tip" v-if="isCopyImgAll">
                                <div class="clipboard-tip-s">
                                    <i class="iconfont">&#xe67e;</i>
                                    <span> 复制成功，在微信/qq等渠道直接粘贴需要单独复制图片</span>
                                </div>
                            </div>
                        </div>

                    </el-scrollbar>
                </div>
        </div>
    </div>
</template>

<script>
    import ShortLinkModule from '../../../components/creativecentermodule/ShortLinkModule.vue'
    import SearchPasswordModule from '../../../components/creativecentermodule/SearchPasswordModule.vue'
    import LongLinkModule from '../../../components/creativecentermodule/LongLinkModule.vue'
    import CodeModule from '../../../components/creativecentermodule/CodeModule.vue'
    export default {
        name: "ImmediatePromotion",
        components: {
            ShortLinkModule,
            SearchPasswordModule,
            LongLinkModule,
            CodeModule,
        },
        data(){
            return{
                imgList:[
                    {
                        src:require('../../../assets/img/ceshi/2.jpg'),
                        state:true,
                    },
                    {
                        src:require('../../../assets/img/ceshi/2.jpg'),
                        state:true,
                    }
                ],
                allCopy:{
                    img:require('../../../assets/img/ceshi/2.jpg'),
                    title:''
                },
                isCopyImg:false,
                isCopyImgAll:false,
                immediatePromotion_radio:1
            }
        },
        mounted() {
            this.allCopy.title = this.$refs.ShortLinkModule.desc;
        },
        methods:{
            imgClick(index){
                this.imgList[index].state = !this.imgList[index].state;
            },
            returnIndex(){
                this.$router.go(-1)
            },
            //复制图片
            copyImg(){
                this.isCopyImg = true;
                setTimeout(()=>{
                    console.log(11111)
                    this.isCopyImg = false;
                },1000)
            },
            copyImgAll(){

                this.isCopyImgAll = true;
                setTimeout(()=>{
                    console.log(11111)
                    this.isCopyImgAll = false;
                },1000)
                this.$refs.myAllCopyTextarea.select();

            },
            changeRadio(){

                console.log(this.immediatePromotion_radio,'immediatePromotion_radio')

                switch (this.immediatePromotion_radio) {
                    case 1:
                        this.allCopy.title = this.$refs.ShortLinkModule.desc
                        break;
                    case 2:
                        this.allCopy.title = this.$refs.SearchPasswordModule.desc
                        break;
                    case 3:
                        this.allCopy.title = this.$refs.LongLinkModule.desc
                        break;

                }



            }
        }
    }
</script>

<style scoped lang="scss">
     .immediatePromotion-wrapper{
         display: flex;
         flex-direction: column;
         .immediatePromotion-top{
             display: flex;
             justify-content: space-between;
             align-items: center;
             margin-bottom: 20px;
             .top-title{
                 color: #444;
                 font-size: 18px;
             }
             .btn-blue {
                 width:80px;
                 background: #574DED;
                 border-radius: 4px;
                 color: #fff;
                 text-align: center;
                 line-height: 32px;
                 cursor: pointer;
             }
         }
         .immediatePromotion-container{
             height: calc(100vh - 152px );
             display: flex;
                 .immediatePromotion-container-left{
                     display: flex;;
                     flex-direction: column;
                     flex: 1;
                     background: #fff;
                     padding: 24px 0 0 24px;
                     -webkit-border-radius: 10px;
                     border-radius: 10px;
                     box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
                     .immediatePromotion-box{
                         display: flex;
                         align-items: center;
                         margin-bottom: 30px;
                         .form-item-title{
                             min-width: 64px;
                             display: inline-block;
                             color: #444;
                             font-size: 14px;
                             text-align: left;
                             align-self: flex-start;
                         }
                         .immediatePromotion-item{
                             width: 90%;
                             margin-left: 20px;
                             .pic-wrap{
                                 display: flex;
                                 overflow-y: hidden;
                                 .pic-item{
                                     margin-right: 10px;
                                     height: 200px;
                                     .pic {
                                         position: relative;
                                         background: #d4d4d9;
                                         text-align: center;
                                         display: table-cell;
                                         vertical-align: middle;
                                         cursor: pointer;
                                         width: 120px;
                                         height: 120px;
                                         border: 1px solid transparent;
                                         img{
                                             max-height: 130px;
                                             max-width: 130px;
                                         }
                                     }
                                     .checked{
                                         position: relative;
                                         border: 1px solid #fE4800;
                                         &:after{
                                             position: absolute;
                                             left: 64px;
                                             top: 58px;
                                             font-family: iconfont;
                                             content: "\e677";
                                             color: #fe4800;
                                             font-size: 14px;
                                         }
                                     }
                                     .default{
                                         &:before{
                                             border:1px solid #FEA100;
                                             border-radius: 4px;
                                             width: 39px;
                                             height: 16px;
                                             line-height: 16px;
                                             position: absolute;
                                             top: 150px;
                                             left: 42px;
                                             content: "默认";
                                             color: #fa8;
                                         }
                                     }
                                 }
                             }
                             ::v-deep .el-radio__input.is-checked .el-radio__inner {
                                 border-color: #FE4800;
                                 background: #FE4800;
                             }
                             ::v-deep .el-radio__input.is-checked+.el-radio__label {
                                 color: #333;
                             }
                             ::v-deep .el-radio__inner:hover {
                                 border-color: #FE4800;
                             }
                             .copywriting-wrapper{
                                 margin-top: 20px;
                             }
                         }
                     }
                 }
                 .immediatePromotion-container-right{
                     background: #fff;
                     margin-left: 20px;
                     padding: 20px;
                     box-sizing: border-box;
                     display: flex;
                     flex-direction: column;
                     box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
                     border-radius: 10px;
                     width: 40%;
                     .img-wrap {
                         width: 130px;
                         margin-top: 70px;
                         img {
                             border-style: none;
                             width: auto;
                             height: auto;
                             max-width: 100%;
                             vertical-align: middle;
                             -ms-interpolation-mode: bicubic;
                         }
                     }
                     .text-review {
                         background: #fff;
                         border-radius: 4px;
                         height: 215px;
                         margin: 24px 0;
                         overflow: auto;
                         padding: 0 16px;
                         /*border: 1px solid #e5e5e5;*/
                         ::v-deep .el-textarea__inner{
                             height: 215px;
                             resize: none;
                             border: 1px solid #e5e5e5;
                         }
                     }
                 }
             }
         .immediatePromotion-list-scrollbar {
             height: 100%;
             ::v-deep .el-scrollbar__wrap {
                 overflow-x: hidden;
             }
             ::v-deep .el-textarea__inner{
                 height: 150px;
                 resize: none;
             }
         }
         .toolbar{
                 display: flex;
                 align-items: center;
                 height: 30px;
                 .copy-btn{
                     background: #FE4800;
                     border-radius: 6px;
                     color: #fff;
                     &:hover,&:focus{
                         background: #ff822e;
                         border-color: #ff822e;
                         color: #FFF;
                     }
                 }
                 .clipboard-tip{

                     .clipboard-tip-s{
                         display: flex;
                         align-items: center;
                         color: #91b615;
                         margin-left: 10px;
                     }
                 }
             }

     }
</style>